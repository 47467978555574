

import {
    ref,
    defineComponent,
    onMounted,
    onActivated,
    createVNode,
    reactive,
} from "vue";

import { _Client } from "@/api-client";
import LogDetail from '@/components/LogDetail/index.vue'
import { GetAllRegionRep, PagedResultOfExaminerInfoDto, GetTrainingRecordLogByExaminerIdRequest, SearchExaminerRequest, PagedResultOfTrainingRecordsDto, SearchTrainingRecordsByExaminerRequest } from "@/api-client/client";
import { LogDetailModel, LogModel } from '@/api-client/modal';
import { List, Modal, message } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import Pagination from '@/components/Pagination/index.vue';

//// TrainingRecords  主列表（考官列表）
const columns = [
    {
        title: 'Region',
        dataIndex: 'regionIdName',
    },
    {
        title: 'Examiner No.',
        dataIndex: 'examinerNo'
    },
    {
        title: 'Examiner Name',
        dataIndex: 'examinerName'
    },
    {
        title: 'PSN Last Session Status',
        dataIndex: 'speakingLastSessionStatusStr'
    },
    {
        title: 'Action',
        dataIndex: 'action',
        fixed: 'right',
        width: 200
    }
]

//// TrainingRecords  主列表（考官列表）
const TrainingRecordsColumns = [
    {
        title: 'Training Date',
        dataIndex: 'trainingDate',
    },
    {
        title: 'Training Type',
        dataIndex: 'trainingType'
    },
    {
        title: 'Training Category',
        dataIndex: 'trainingCategory'
    },
    {
        title: 'Training Session',
        dataIndex: 'trainingSession'
    },
    {
        title: 'Trainer',
        dataIndex: 'trainer'
    },
    {
        title: 'Training Status',
        dataIndex: 'trainingStatus'
    }
]
export default defineComponent({
    components: {

        Pagination,
        LogDetail
    },
    setup() {
        const tableLoading = ref<boolean>(false);
        const trainingRecordsTableLoading = ref<boolean>(false);
        const tableSource = ref(new PagedResultOfExaminerInfoDto());
        const trainingRecordsTableSource = ref(new PagedResultOfTrainingRecordsDto());
        const titleTrainingRecords = ref<String>("");
        const showTrainingRecordsModal = ref<boolean>(false);
        const searchParames = ref<SearchExaminerRequest>(new SearchExaminerRequest({ pageIndex: 1, pageSize: 10 }));
        const searchTrainingRecordsParames = ref<SearchTrainingRecordsByExaminerRequest>(new SearchTrainingRecordsByExaminerRequest({ examinerId: "", pageIndex: 1, pageSize: 10 }));
        const logDetailRef = ref(null)
        const region = reactive<{
            regionList: GetAllRegionRep[],
        }>({
            regionList: new Array<GetAllRegionRep>(),
        });

        const logState = reactive<{
            logSearchParames: GetTrainingRecordLogByExaminerIdRequest,
            logDetail: LogDetailModel,
            showLogDialog: Function,
            getLogList: Function
        }>({
            logSearchParames: new GetTrainingRecordLogByExaminerIdRequest(<GetTrainingRecordLogByExaminerIdRequest>{ pageIndex: 1 }),
            logDetail: new LogDetailModel(),

            showLogDialog: async (examinerId: string) => {
                logState.logSearchParames.pageIndex = 1;
                logState.logSearchParames.examinerId = examinerId
                await logState.getLogList()
            },

            getLogList: async () => {
                try {
                    let logDetail = JSON.parse(JSON.stringify(await _Client.trainingRecordsClient.searchTrainingRecordLogByExaminerId(logState.logSearchParames)))
                    if (!logDetail) {
                        return;
                    }
                    logDetail.list = logDetail.list?.map((logItem: { createdOn: Date; operation: string; createdBy: string; }) => {
                        return new LogModel({
                            createdTime: logItem.createdOn,
                            operation: logItem.operation,
                            userName: logItem.createdBy
                        })
                    })
                    logState.logDetail = new LogDetailModel(logDetail)
                }
                finally {
                    (logDetailRef.value as any).showLogModal = true
                }
            }
        })


        async function getRegionList() {
            region.regionList = await _Client.regionClient.getAll(true)
        }

        const search = (() => {
            searchParames.value.pageIndex = 1;
            getList();
        })

        const getList = (() => {
            tableLoading.value = true;
            _Client.trainingRecordsClient.search(searchParames.value!).then(rep => {
                tableSource.value = rep;
            }).finally(() => tableLoading.value = false)
        })

        const resetSearchParams = () => {
            searchParames.value = new SearchExaminerRequest(<SearchExaminerRequest>{ pageIndex: 1 })

            getList();
        }

        const gettrainingRecords = (() => {
            trainingRecordsTableLoading.value = true;
            _Client.trainingRecordsClient.searchTrainingRecordByExaminerId(searchTrainingRecordsParames.value!).then(rep => {
                trainingRecordsTableSource.value = rep;
            }).finally(() => trainingRecordsTableLoading.value = false)

        })


        const showtrainingRecords = ((examinerNo: string, examinerId: string, examinerName: string) => {
            searchTrainingRecordsParames.value.pageIndex = 1;
            showTrainingRecordsModal.value = true;
            titleTrainingRecords.value = "View Detail：" + examinerName + " " + examinerNo;
            searchTrainingRecordsParames.value.examinerId = examinerId;
            gettrainingRecords();
        })

        const closetrainingRecords = (() => {
            showTrainingRecordsModal.value = false;
            titleTrainingRecords.value = "";
            searchTrainingRecordsParames.value.examinerId = "";
        })

        onMounted(() => {
            getRegionList();
            getList();
        });

        onActivated(() => {

        });


        return {
            tableLoading,
            trainingRecordsTableLoading,
            showTrainingRecordsModal,
            region,
            columns,
            TrainingRecordsColumns,
            tableSource,
            trainingRecordsTableSource,
            searchParames,
            searchTrainingRecordsParames,
            titleTrainingRecords,
            logState,
            logDetailRef,
            close,
            search,
            getList,
            resetSearchParams,
            gettrainingRecords,
            showtrainingRecords,
            closetrainingRecords

        };
    }
});

